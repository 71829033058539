<template>
  <v-app>
    <v-main>
      <v-container
          class="fill-height"
          fluid
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12"
              sm="8"
              md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                  color="primary"
                  dark
                  flat
              >
                <v-toolbar-title>One Time Login</v-toolbar-title>
                <!--                <v-spacer></v-spacer>-->
                <!--                <v-tooltip bottom>-->
                <!--                  <template v-slot:activator="{ on }">-->
                <!--                    <v-btn-->
                <!--                        :href="source"-->
                <!--                        icon-->
                <!--                        large-->
                <!--                        target="_blank"-->
                <!--                        v-on="on"-->
                <!--                    >-->
                <!--                      <v-icon>mdi-code-tags</v-icon>-->
                <!--                    </v-btn>-->
                <!--                  </template>-->
                <!--                  <span>Source</span>-->
                <!--                </v-tooltip>-->
              </v-toolbar>
              <v-card-text>
                <!--                <script src="https://apis.google.com/js/platform.js" async defer></script>-->


                <v-row v-if="isAllow"
                       justify="center"
                       align="center"
                >
                  <span class="title">Connect RTBSystem Assistant</span>
                  <!--                  <v-btn>-->
                  <a :href="appUrl"
                     target="_blank">
                    <v-img max-width="150" class="ml-5"

                           :src="require(`@/assets/RTBSystemAssistantLogo.svg`)"></v-img>
                    <!--                  </v-btn>-->
                    <!--                  <v-icon x-large>mdi-facebook</v-icon>-->
                    <!--                  <div class="g-signin2" data-onsuccess="onSignIn"></div>-->

                  </a>
                </v-row>
                <v-row v-else
                       justify="center"
                       align="center"
                >
                  <span class="title">Unauthorized</span>

                  <!--                  <div class="g-signin2" data-onsuccess="onSignIn"></div>-->
                </v-row>


                <!--                <v-form>-->
                <!--                  <v-text-field-->
                <!--                      label="Login"-->
                <!--                      name="login"-->
                <!--                      prepend-icon="mdi-account"-->
                <!--                      type="text"-->
                <!--                  ></v-text-field>-->

                <!--                  <v-text-field-->
                <!--                      id="password"-->
                <!--                      label="Password"-->
                <!--                      name="password"-->
                <!--                      prepend-icon="mdi-lock"-->
                <!--                      type="password"-->
                <!--                  ></v-text-field>-->
                <!--                </v-form>-->
              </v-card-text>
              <!--              <v-card-actions>-->
              <!--                <v-spacer></v-spacer>-->
              <!--                <v-btn color="primary">Login</v-btn>-->
              <!--              </v-card-actions>-->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'

export default {
  name: 'OTL',
  data() {
    return {
      isAllow: false,
      appUrl: ''
    }
  },
  async mounted() {
    // http://rtb-dash/#/OTL?token=31f42c65-d142-4d34-8bb6-cb0b63b1f943
    // https://www.facebook.com/v18.0/dialog/oauth?client_id=%3CYOUR_APP_ID%3E&http://rtb-dash/#/OTL?token=31f42c65-d142-4d34-8bb6-cb0b63b1f943=%3CYOUR_URL%3E&scope=ads_management
    if (this.$route.query.token) {
      console.log(this.$route.query.token)
      try {
        const {data} = await axios.get('/login/otl/status?token=' + this.$route.query.token)
        // console.log(data, window.location.origin)

        // const redirect_uri = encodeURIComponent(`${window.location.origin}/#/OTL?token=${this.$route.query.token}`)
        const redirect_uri = encodeURIComponent(`https://optimization.rtbsystem.biz/#/OTL?token=${this.$route.query.token}`)

        // https://www.facebook.com/v18.0/dialog/oauth?client_id=<YOUR_APP_ID>&redirect_uri=<YOUR_URL>&scope=ads_management
        this.appUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${data.client_id}&redirect_uri=${redirect_uri}&scope=ads_management`

        this.isAllow = true
      } catch (e) {
        console.log(e)
      }

    }
  },
  methods: {
    // async login () {
    // try {
    //   if (await this.$store.dispatch(AUTH_REQUEST, this)) {
    //     window.console.info('LOGIN OK',)
    //     await this.$router.push('/')
    //   } else {
    //     window.console.info('LOGIN ERROR',)
    //   }
    // } catch (e) {
    //   window.console.info('LOGIN ERROR', e)
    // }

    // let authCode
    // try {
    //   authCode = await this.$gAuth.getAuthCode()
    // } catch (e) {
    //   window.console.error(e)
    //   return
    // }
    // window.console.info('authCode', authCode)
    //
    // if (authCode) {
    //
    //   const response = await this.$http.get('http://localhost:3000/login/google/callback', {
    //     params: {
    //       code: authCode,
    //       state: '',
    //       origin: window.origin
    //     }
    //   })
    //   window.console.info('POST response', response)
    // }
    // }
  }
}
</script>

<style scoped>

</style>